module.exports = {
  login: {
    userLogin: "Users Login",
    rememberPassword: "Rememb",
    codeLogin: "Login with mobile phone Number",
    login: "Login in",
    username: "Account Name",
    password: "Password",
    code: "Verification code",
    phone: "Cell-phone number",
    usernameLogin: "Account Password Login",
    phoneNull: "Please enter your mobile phone number",
    loginSuccess:"Login successfully",
    user_name_rules:"Please enter your account number"
  },
 userInfo:{
  inputSearch:"Please enter the account name to search",
  btnSearch:"Search",
  btnReset:"Reset",
  btnAdd:"+Added",
  btnEdit:"Edit",
  btnDelete:"Delete",
  dataList_columns_index:"Serial number"
 },
 router:{
  unitMa:"Department",
  userInfo:"Consumer",
  farmInfo:"Farm",
  ProductionMa:"Production",
  CarProtect:"Nanny van",
  MedicineMa:"Liquid medicine",
  CarDriverless:"Driverless cars",
  VehicleInfo:"Status bar"
}
};