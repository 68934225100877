<template>
  <a-config-provider :locale="locale">
    <div id="app" >
      <div>
        <!-- <button @click="changeLocale('en-us')" v-show="$i18n.locale=='zh-cn'">English</button >
        <button @click="changeLocale('zh-cn')" v-show="$i18n.locale=='en-us'">中文</button > -->
      </div>
    <router-view/>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale(localStorage.getItem("i18n")||"zh-cn")

// const EN = 'en-us'
// const ZH = 'zh-cn'
export default {
  name: 'App',
  data () {
    return {
      locale: zhCN,
      // localeval: 'zh-cn'
    }
  },
  created(){
    if ((localStorage.getItem("i18n")||"zh-cn") === "zh-cn"){
      this.locale = zhCN
    }else{
      this.locale = null
    }
    // console.log()
    // this.$i18n.locale = "en-us"
    console.log("mounted")
  },
  methods: {
    // moment,
    changeLocale (localeval) {
      console.log("changeLocale",localeval)
      localStorage.setItem("i18n",localeval)
      location.reload()
      // localStorage.clear()
      // this.localeval = localeval
      // if (localeval === EN) {
      //   moment.locale(EN)
      //   this.$i18n.locale = EN
      //   this.locale = null
      // } else {
      //   moment.locale(ZH)
      //   this.$i18n.locale = ZH
      //   this.locale = zhCN
      // }
    }
  }
}
</script>
