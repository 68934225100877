import { login, login_sms } from "@/api/login";
import storage from "@/utils/storage";
import {
  getToken,
  setToken,
  removeToken,
  getLogin_name,
  setLogin_name,
  retLogin_name,
} from "@/utils/auth";
import router from "@/router";

const user = {
  state: {
    token: getToken(),
    systemName: "SaaS云平台客户端",
    user_id: storage.getItem("user_id") || "",
    login_name: getLogin_name(),
  },

  mutations: {
    SET_SYSTEM_NAME: (state, systemName) => {
      state.systemName = systemName;
      storage.setItem("systemName", systemName);
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
      storage.setItem("token", token);
    },

    SET_LOGIN_NAME: (state, login_name) => {
      state.login_name = login_name;
      // Cookies.set('login_name', login_name, { expires: 7 });
      // storage.setItem("login_name", login_name);
    },
    SET_USER_ID: (state, user_id) => {
      state.user_id = user_id;
      storage.setItem("user_id", user_id);
    },
  },
  actions: {
    // 登录
    login({ commit }, userInfo) {
      const login_name = userInfo.user_name.trim();
      const password = userInfo.password;
      const user_id = userInfo.user_id;
      const login_type = userInfo.login_type;
      // const ver_code = userInfo.ver_code.trim();
      return new Promise((resolve, reject) => {
        login(login_name, password, user_id, login_type)
          .then((res) => {
            const data = res.err_data;
            setToken(data.access_token);
            setLogin_name(data.user_name);
            commit("SET_TOKEN", data.access_token);
            // commit("SET_FARM_ID", data.farm_id);
            commit("SET_USER_ID", data.user_id);
            commit("SET_LOGIN_NAME", data.user_name);
            resolve();
          })
          .catch((error) => {
            // this.$message.error("登录失败");
            reject(error);
          });
      });
    },
    // 验证码登录
    login_s({ commit }, userInfo) {
      const telephone = userInfo.telephone;
      const code = userInfo.code;
      const login_type = userInfo.login_type;
      return new Promise((resolve, reject) => {
        login_sms(telephone, code, login_type)
          .then((res) => {
            const data = res.err_data;
            setToken(data.access_token);
            commit("SET_TOKEN", data.access_token);
            // commit("SET_FARM_ID", data.farm_id);
            commit("SET_USER_ID", data.user_id);
            commit("SET_LOGIN_NAME", data.user_name);
            resolve();
          })
          .catch((error) => {
            // this.$message.error("登录失败");
            reject(error);
          });
      });
    },
    // 查询所有系统配置
    // systemsetting({ commit }, userInfo) {
    //   return new Promise((resolve, reject) => {
    //     systemsetting()
    //       .then((res) => {
    //         const data = res.err_data;
    //         document.title = data.system_name;
    //         commit("SET_SYSTEM_NAME", data);
    //         resolve();
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // },
    // 获取用户信息
    // getInfo({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     getInfo()
    //       .then((res) => {
    //         const data = res.err_data;
    //         commit("SET_NAME", data.name);
    //         commit("SET_USER_ID", data.user_id);
    //         commit("SET_LOGIN_NAME", data.login_name);
    //         commit("SET_ROLE_ID", data.role_id);
    //         resolve(res);
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // },

    // 退出
    logout() {
      return new Promise((resolve) => {
        removeToken();
        retLogin_name();
        // sessionStorage.clear();
        // localStorage.clear();
        // commit("permission/SET_ROUTES", []);
        // removeToken(); // 必须先移除token
        localStorage.removeItem("token"); //只需要在退出成功后清除了就可以
        router.replace("/login");
        resolve();

        // logout,getInfo(state.token).then(() => {
        //   commit('SET_TOKEN', '')
        //   commit('SET_ROLES', [])
        //   commit('SET_PERMISSIONS', [])
        //   removeToken() // 必须先移除token
        //   resolve()
        // }).catch(error => {
        //   reject(error)
        // })
      });
    },

    // 移除token
    resetToken({ commit }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", "");
        removeToken();
        resolve();
      });
    },
  },
};

export default user;
