var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-container"},[_vm._m(0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loginShowQR),expression:"loginShowQR"}],staticClass:"login-container-form"},[_c('div',{staticClass:"login-container-form-text"},[_vm._v(_vm._s(_vm.$t('login.userLogin')))]),_c('a-form-model',{ref:"loginForm",attrs:{"model":_vm.loginForm,"rules":_vm.loginRules}},[(!_vm.flag)?_c('a-form-model-item',{ref:"user_name",attrs:{"prop":"user_name"}},[_c('span',{staticClass:"svg-container",staticStyle:{"color":"#ebebeb"}},[_c('svg-icon',{attrs:{"icon-class":"user"}})],1),_c('a-input',{attrs:{"placeholder":_vm.$t('login.username'),"name":"username","type":"text","tabindex":"1","autocomplete":"off"},on:{"blur":() => {
              _vm.$refs.user_name.onFieldBlur();
            }},model:{value:(_vm.loginForm.user_name),callback:function ($$v) {_vm.$set(_vm.loginForm, "user_name", $$v)},expression:"loginForm.user_name"}})],1):_vm._e(),(!_vm.flag)?_c('a-form-model-item',{ref:"password",attrs:{"prop":"password"}},[_c('span',{staticClass:"svg-container",staticStyle:{"color":"#ebebeb"}},[_c('svg-icon',{attrs:{"icon-class":"password"}})],1),_c('a-input',{key:_vm.passwordType,attrs:{"type":_vm.passwordType,"placeholder":_vm.$t('login.password'),"name":"password","tabindex":"2","autocomplete":"off"},on:{"blur":() => {
              _vm.$refs.password.onFieldBlur();
            }},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleLogin.apply(null, arguments)}},model:{value:(_vm.loginForm.password),callback:function ($$v) {_vm.$set(_vm.loginForm, "password", $$v)},expression:"loginForm.password"}}),_c('span',{staticClass:"show-pwd",on:{"click":_vm.showPwd}},[_c('svg-icon',{attrs:{"icon-class":_vm.passwordType === 'password' ? 'eye' : 'eye-open'}})],1)],1):_vm._e(),(_vm.flag)?_c('a-form-model-item',{ref:"telephone",attrs:{"prop":"telephone"}},[_c('span',{staticClass:"svg-container",staticStyle:{"color":"#ebebeb"}},[_c('a-icon',{attrs:{"type":"phone"}})],1),_c('a-input',{attrs:{"maxLength":11,"type":"text","tabindex":"3","auto-complete":"off","placeholder":_vm.$t('login.phone')},on:{"blur":() => {
              _vm.$refs.telephone.onFieldBlur();
            }},model:{value:(_vm.loginForm.telephone),callback:function ($$v) {_vm.$set(_vm.loginForm, "telephone", $$v)},expression:"loginForm.telephone"}})],1):_vm._e(),(_vm.flag)?_c('a-form-model-item',{ref:"code",staticStyle:{"width":"70%","margin-right":"8px"},attrs:{"prop":"code"}},[_c('span',{staticClass:"svg-container",staticStyle:{"color":"#ebebeb"}},[_c('a-icon',{attrs:{"type":"message"}})],1),_c('a-input',{attrs:{"type":"text","placeholder":_vm.$t('login.code'),"tabindex":"4","auto-complete":"on"},model:{value:(_vm.loginForm.code),callback:function ($$v) {_vm.$set(_vm.loginForm, "code", $$v)},expression:"loginForm.code"}}),_c('a-button',{staticClass:"code-img",attrs:{"size":"small","disabled":_vm.getCodeBtnDisable},on:{"click":function($event){return _vm.getCode()}}},[_c('span',[_vm._v(" "+_vm._s(_vm.codeBtnWord))])])],1):_vm._e(),(_vm.flag)?_c('div',{staticClass:"tips",on:{"click":_vm.nohasCode}},[_vm._v(_vm._s(_vm.$t('login.usernameLogin')))]):_vm._e(),(!_vm.flag)?_c('div',{staticClass:"tips",on:{"click":_vm.hasCode}},[_vm._v(_vm._s(_vm.$t('login.codeLogin')))]):_vm._e(),_c('div',{staticClass:"flex align-center m-b-20"},[(!_vm.flag)?_c('a-checkbox',{model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v(_vm._s(_vm.$t('login.rememberPassword')))]):_vm._e()],1),_c('a-button',{staticStyle:{"width":"100%","margin-bottom":"30px"},attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.handleLogin}},[_vm._v(_vm._s(_vm.$t("login.login")))])],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-container-text flex justify-center align-center"},[_c('div',{staticClass:"flex flex-column justify-center align-center"})])
}]

export { render, staticRenderFns }