import request from "@utils/request";

// 查询所有系统配置
// export default {
//   systemsetting() {
//     return request({
//       url: "/api/v1/systemsetting/alllist",
//       method: "get",
//     });
//   },
//   getVercode() {
//     return request({
//       url: "/api/v1/login/vercode",
//       method: "get",
//     });
//   },
// };

// 登录方法
export function login(user_name, password, user_id, login_type) {
  const data = {
    user_name,
    password,
    user_id,
    login_type,
  };
  return request({
    url: "/login",
    // headers: {
    //   isToken: false,
    // },
    method: "post",
    data: data,
  });
}

//获取短信
export function smsCode(data) {
  return request({
    url: "/sms/code",
    method: "post",
    data: data,
  });
}

// 短信验证登录
export function login_sms(telephone, code, login_type) {
  const data = {
    telephone,
    code,
    login_type,
  };
  return request({
    url: "/sms/login",
    // headers: {
    //   isToken: false,
    // },
    method: "post",
    data: data,
  });
}
