import Vue from "vue";
import VueI18n from "vue-i18n";
import LangENUS from "../common/lang/en-us";
import LangZHCN from "../common/lang/zh-cn";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem("i18n") || "zh-cn",
  messages: {
    "en-us": LangENUS,
    "zh-cn": LangZHCN,
  },
});

const $t = function (key) {
  var values = [],
    len = arguments.length - 1;
  while (len-- > 0) values[len] = arguments[len + 1];

  return i18n._t.apply(
    i18n,
    [key, i18n.locale, i18n._getMessages(), this].concat(values)
  );
};

export { i18n, $t };
