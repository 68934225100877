<template>
  <a-layout :class="['layout', classObj]">
    <a-layout-header class="layout-header" style="background: #fff;"><topbar /></a-layout-header>
    <a-layout class="layout-content">
      <a-layout-sider class="full-height p-t-10 sidebar-container" :width="wd"
        ><sider-bar
      /></a-layout-sider>
      <a-layout-content class="layout-wrap main-container full-height">
        <app-main />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import SiderBar from "./components/SiderBar.vue";
import AppMain from "./components/AppMain.vue";
import Topbar from "./components/Topbar.vue";

export default {
  name: "Layout",
  components: {
    SiderBar,
    AppMain,
    Topbar,
  },
  data() {
    return {};
  },
  methods: {},

  props: {},
  watch: {},
  computed: {
    wd() {
      return this.sidebar.opened ? 280 : 56;
    },
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
@import "~@/styles/mixin.less";
.layout {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  &-header {
    height: 64px;
    padding: 0 !important;
  }
  &-content {
    height: calc(100vh - 64px);
  }

  &-footer {
    height: 64px;
  }
  &-wrap {
    overflow: auto;
    #scrollBar();
  }
}

</style>
