import Vue from "vue";
import App from "./App.vue";
import {i18n} from "./i18n/i18n";
import router from "./router";
import store from "./store";
import "./BaseComponent/lazy_useUI";
import "@utils/rem";
import "@/icons"; // icon
import api from "./api/index";
import "@/styles/index.less";
import "@/router/permission";
// import 'ant-design-vue/dist/antd.css'
import Antd from 'ant-design-vue/es'

Vue.config.productionTip = false;
Vue.use(Antd)
Vue.prototype.$api = api;
Vue.prototype.$setLoading = function (props) {
  if (typeof props === "boolean") props = { spinning: props };
  if (Object.prototype.toString.call(props) !== "[object Object]") props = {};
  this.$app.loadingProps = {
    tip: "加载中...",
    ...props,
  };
};
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  data: { Bus: new Vue() },
}).$mount("#app");
