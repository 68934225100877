import router from "./index";
import store from "@/store/index";
import getPageTitle from "@/utils/get-page-title";
const whitelist = ["/login"];
router.beforeEach((to, from, next) => {
  document.title = getPageTitle(to.meta.title);
  // next();
  // next();
  if (!store.state.user.token) {
    // 未登录  页面是否需要登录
    if (to.matched.length > 0 && whitelist.includes(to.path)) {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    // 用户已经登录  路由的访问权限
    if (!store.state.permissions.permissionList) {
      store.dispatch("permissions/FETCH_PERMISSION").then(() => {
        next({
          path: to.path,
        });
      });
    } else {
      // store存在权限
      if (to.path !== "/Login") {
        next();
      } else {
        next(from.fullPath);
      }
    }
  }
});
