<template>
  <div>
    <a-modal
      :title="getTitle"
      width="32%"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :centered="true"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <template>
        <a-form-model
          ref="refForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <!-- 原密码 -->
          <a-form-model-item label="原密码:" prop="old_password">
            <a-input
              placeholder="请输入原密码"
              v-model="form.old_password"
              allowClear
              @blur="getOld($event)"
            />
          </a-form-model-item>
          <!-- 新的登录密码 -->
          <a-form-model-item label="新的登录密码:" prop="password">
            <a-input
              placeholder="请输入新的登录密码"
              v-model="form.password"
              allowClear
              :maxLength="20"
              :minLength="6"
            />
            <!-- 再次输入登录密码 -->
          </a-form-model-item>
          <a-form-model-item
            label="请再次输入登录密码:"
            prop="confirm_password"
          >
            <a-input
              placeholder="请再次输入登录密码"
              v-model="form.confirm_password"
              allowClear
              :maxLength="20"
              :minLength="6"
            />
          </a-form-model-item>
        </a-form-model>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {
  editPasswordUserinfo,
  originalPasswordUserinfo,
} from "@api/unitMa/userInfo";
import { mapState } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const equalToPasswords = (rule, value, callback) => {
      if (this.form.password == this.form.old_password) {
        callback(new Error("新密码不能与旧密码一致"));
      } else {
        callback();
      }
    };
    const equalToPassword = (rule, value, callback) => {
      if (this.form.password !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    const rules = {
      old_password: [
        { required: true, message: "请输入原密码", trigger: "blur" },
        { min: 6, max: 20, message: "长度在 6 到 20个字符", trigger: "blur" },
        {
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (value === "******") {
              callback();
              return;
            }
            var passwordreg = /^(?=.*[a-zA-Z])(?=.*\d)[0-9A-Za-z]{6,20}$/;
            console.log(passwordreg.test(value));
            if (!passwordreg.test(value)) {
              callback(new Error("密码必须同时包含字母、数字、长度6~20位"));
            } else {
              callback();
            }
          },
        },
      ],
      password: [
        { required: true, message: "请输入新的登录密码", trigger: "blur" },
        { min: 6, max: 20, message: "长度在 6 到 20个字符", trigger: "blur" },
        { required: true, validator: equalToPasswords, trigger: "blur" },
        {
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (value === "******") {
              callback();
              return;
            }
            var passwordreg = /^(?=.*[a-zA-Z])(?=.*\d)[0-9A-Za-z]{6,20}$/;
            console.log(passwordreg.test(value));
            if (!passwordreg.test(value)) {
              callback(new Error("密码必须同时包含字母、数字、长度6~20位"));
            } else {
              callback();
            }
          },
        },
      ],

      confirm_password: [
        { required: true, message: "确认密码不能为空", trigger: "blur" },
        { required: true, validator: equalToPassword, trigger: "blur" },
      ],
    };

    return {
      // searchForm: {
      //   user_id: "7397jsq",
      //   password: "",
      //   confirm_password: "",
      // },
      getTitle: "修改登录密码",
      dialogRelationVisible: false,
      rules,
      labelCol: { span: 7 },
      wrapperCol: { span: 14 },
      confirmLoading: false,
      form: {
        user_id: "",
        password: "",
        confirm_password: "",
        old_password: "",
      },
      timeout: null,
      pos: "",
    };
  },
  methods: {
    //输入框输入值后监听失去焦点的值然后调用接口比对
    getOld(e) {
      let password = e.target.value;
      this.getOldPassword(password);
    },
    // 重复密码调用接口查重
    getOldPassword(password) {
      const data = {
        password: password,
      };
      originalPasswordUserinfo(data)
        .then((res) => {
          if (res.err_code == 200) {
            this.$message.success("原密码输入正确");
          }
        })
        .catch(() => {
          this.resetData();
          // this.$message.error("您输入的与原密码不一致，请重新输入");
        });
    },
    //表单重置校验
    resetData() {
      this.$refs.refForm.resetFields();
    },
    //确定
    handleOk() {
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          editPasswordUserinfo(this.form).then((res) => {
            if (res.err_code === 200) {
              this.$message.success("修改成功");
              this.$router.push({ path: "/login" });
            }
          });
          this.$emit("sure", () => {
            this.resetData();
          });
        }
      });
    },
    //取消
    handleCancel() {
      this.$emit("close", () => {
        this.resetData();
      });
    },
    //确定关联
    sureRelationHandle() {
      this.closeRelationHandle();
    },
    //关闭关联
    closeRelationHandle() {
      this.dialogRelationVisible = false;
    },
    //关联管理事件
    relationHandle() {
      this.dialogRelationVisible = true;
    },
  },

  watch: {
    old_password(curVal, oldVal) {
      // 实现input连续输入，只发一次请求
      console.log("oldVal--", oldVal);
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getListPOI(curVal);
      }, 300);
    },
  },
  computed: {
    ...mapState({
      user_id: (state) => state.user.user_id,
    }),
  },
  mounted() {
    this.form.user_id = this.user_id;
  },

  created() {
    // this.getOldPassword();
  },
};
</script>
<style lang="less" scoped>
label {
  display: inline-block;
  width: 100px;

  text-align: right;
}
</style>
