import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@views/Login";
import Layout from "@views/Layout";
import NotFound from "@views/404.vue";
import {$t} from "../i18n/i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/Login",
    name: "Login",
    component: Login,
    meta: { hideMenu: true, title: "登录" },
  },
];
export const dynamicRoutes = [
  {
    path: "/",
    menu_url: "/",
    component: Layout,
    redirect: "UnitMa",
    meta: {},
    children: [
      {
        path: "UnitMa",
        menu_url: "/UnitMa",
        name: "UnitMa",
        meta: { title: $t('router.unitMa'), icon: "icon-unit" },
        component: () => import("@/views/UnitMa/"),
        redirect: "/UnitMa/UserInfo",
        children: [
          {
            path: "UserInfo",
            menu_url: "/UnitMa/UserInfo",
            name: "UserInfo",
            meta: { title:  $t('router.userInfo') },
            component: () => import("@/views/UnitMa/UserInfo"),
          },
          {
            path: "FarmInfo",
            menu_url: "/UnitMa/FarmInfo",
            name: "FarmInfo",
            meta: { title: $t('router.farmInfo') },
            component: () => import("@/views/UnitMa/FarmInfo"),
          },
        ],
      },
      {
        path: "ProductionMa",
        menu_url: "/ProductionMa",
        name: "ProductionMa",
           meta: { title: $t('router.ProductionMa'), icon: "icon-product" },
        component: () => import("@/views/ProductionMa/"),
        redirect: "/ProductionMa/CarProtect",
        children: [
          {
            path: "CarProtect",
            menu_url: "/ProductionMa/CarProtect",
            name: "CarProtect",
            meta: { title: $t('router.CarProtect') },
            component: () => import("@/views/ProductionMa/CarProtectMa"),
          },
          {
            path: "MedicineMa",
            menu_url: "/ProductionMa/MedicineMa",
            name: "MedicineMa",
            meta: { title:$t('router.MedicineMa') },
            component: () => import("@/views/ProductionMa/MedicineMa"),
          },
          {
            path: "CarDriverless",
            menu_url: "/ProductionMa/CarDriverless",
            name: "CarDriverless",
            meta: { title: $t('router.CarDriverless') },
            component: () => import("@/views/ProductionMa/CarDriverless"),
          },
          {
            path: "VehicleInfo",
            menu_url: "/ProductionMa/VehicleInfo",
            name: "VehicleInfo",
            meta: { title: $t('router.VehicleInfo') },
            component: () => import("@/views/ProductionMa/VehicleInfo"),
          },
        ],
      },
    ],
  },
  {
    path: "*",
    component: NotFound,
    meta: { hideMenu: true },
  },
];
const router = new VueRouter({
  routes: routes.concat(dynamicRoutes),
});

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
export default router;
