const loadMore = {
  state: {
    loading: false,
  },

  mutations: {
    SHOW_LOAD: (state, load) => {
      state.loading = load;
    },
  },

  actions: {
    setLoading: ({ commit }, load) => {
      commit("SHOW_LOAD", load);
    },
  },
  getters: {
    isLoading: (state) => {
      return state.loading;
    },
  },
};

export default loadMore;
