export const recursionMenuList = (sidebarMenu, level = 0) => {
  var realRoutes = [];
  sidebarMenu.forEach((item, i) => {
    if (item.children && item.children.length > 0) {
      item.children = recursionMenuList(item.children, ++level);
    }
    item.title = item.meta.title;
    item.icon = item.meta.icon;
    item.key = `${i}${new Array(level).fill(`_${i}`)}`;
    realRoutes.push(item);
  });
  return realRoutes;
};

