module.exports = {
  login: {
    userLogin: "用户登录",
    rememberPassword: "记住密码",
    codeLogin: "手机动态密码登录",
    login: "登录",
    username: "账号名",
    password: "密码",
    code: "验证码",
    phone: "手机号",
    usernameLogin: "账号密码登录",
    phoneNull: "请输入手机号码",
    loginSuccess: "登录成功",
    user_name_rules: "请输入您的账号",
  },
  userInfo: {
    inputSearch: "请输入账号名搜索",
    btnSearch: "查询",
    btnReset: "重置",
    btnAdd: "+新增",
    btnEdit: "编辑",
    btnDelete: "删除",
    dataList_columns_index: "序号",
  },
  router:{
    unitMa:"单位信息管理",
    userInfo:"用户信息管理",
    farmInfo:"农场信息管理",
    ProductionMa:"生产信息管理",
    CarProtect:"智能补给平台管理",
    MedicineMa:"药水种类管理",
    CarDriverless:"果园机器人管理",
    VehicleInfo:"状态信息管理"
  }
};
