// import { fetchPermission } from "../../api/index";
import router, { /* router, */ dynamicRoutes } from "@/router/index";
// import dynamicRouter from "../../router/dynamic-router"
// import { recursionRouter,setDefaultRoute } from "../../utils/recursion-router"
import { recursionMenuList } from "@/utils/tools";
export default {
  namespaced: true,
  state: {
    permissionList: null,
    sidebarMenu: [], // 导航菜单
    currentMenu: "", // 高亮
  },
  getters: {},
  mutations: {
    SET_PERMISSION(state, routes) {
      state.permissionList = routes;
    },
    CLEAR_PERMSSION(state) {
      state.permissionList = null;
    },
    SET_MENU(state, menu) {
      state.sidebarMenu = menu;
    },
    CLEAR_MENU(state) {
      state.sidebarMenu = [];
    },
  },
  // 异步访问
  actions: {
    // eslint-disable-next-line no-unused-vars
    async FETCH_PERMISSION({ commit, state }) {
      //   let permissionList = await fetchPermission();
      // 筛选
      // let routes = recursionRouter(permissionList,dynamicRouter);
      let routes = [];
      let MainContainer = dynamicRoutes.find((v) => !v.meta.hideMenu);
      let children = MainContainer.children;
      children.push(...routes);

      // 生成菜单
      commit("SET_MENU", recursionMenuList(children));

      // 设置默认路由
      //   setDefaultRoute([MainContainer]);
      // 初始化路由
      let initialRoutes = router.options.routes;
      //   router.addRoutes(dynamicRoutes);
      commit("SET_PERMISSION", [...initialRoutes]);
      // commit("SET_PERMISSION",initialRoutes)
    },
  },
};
