import defaultSettings from "@/settings";
import store from "@/store";

const title = defaultSettings.title || store.getters.systemName;

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`;
  }
  return `${title}`;
}
