<template>
  <a-menu
    :inlineIndent="inlineIndent"
    :defaultSelectedKeys="[$route.path]"
    :openKeys="openKeys"
    :mode="mode"
    :collapsed="collapsed"
    @openChange="onOpenChange"
    @click="menuClick"
  >
    <template v-for="item in sidebarMenu">
      <a-menu-item v-if="!item.children" :key="item.menu_url">
        <i :class="['iconfont', item.icon]" class="m-r-10" />
        <span class="menu_name">{{ item.title }}</span>
      </a-menu-item>

      <sub-menu v-else :key="item.menu_url" :menu-info="item" />
    </template>
  </a-menu>
</template>

<script>
import { mapState } from "vuex";
import SubMenu from "@components/DynamicSider";
export default {
  components: {
    SubMenu,
  },
  data() {
    return {
      /*menuList: [
        {
          key: "1",
          title: "系统信息管理",
          path: "/system_infomation_manage",
          icon: "iconfont iconshezhiziduan",
          children: [
            {
              key: "2",
              title: "项目信息管理",
              path: "/system_base/system_information",
              icon: "",
            },
            {
              key: "3",
              title: "系统组织机构管理",
              path: "/system_base/institul_framework",
              icon: "",
            },
            {
              key: "4",
              title: "系统人员管理",
              path: "/system_base/personnel_manage",
              icon: "",
            },
            {
              key: "5",
              title: "系统权限管理",
              path: "/system_base/jurisdiction_manage",
              icon: "",
            },
            {
              key: "6",
              title: "项目业务字典管理",
              path: "/system_dictionary_management",
              icon: "",
              children: [
                {
                  key: "6_1",
                  title: "材料设备管理",
                  path: "/dictionary_material_manage",
                  icon: "",
                  children: [
                    {
                      key: "6_1_1",
                      title: "材料管理",
                      path: "/system_base/material_manage",
                      icon: "",
                    },
                    {
                      key: "6_1_2",
                      title: "机械设备管理",
                      path: "/system_base/machine_manage",
                      icon: "",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],*/
      // 菜单缩进
      inlineIndent: 12,
      // 默认不折叠
      collapsed: false,
      // 全部父节点
      rootSubmenuKeys: [],
      openKeys: [], //默认展开的节点
      defaultOpenKeys: [],
      // 选中的子菜单项
      defaultSelectedKeys: [this.$route.path],
    };
  },
  computed: {
    mode: {
      get() {
        return this.sidebar.opened ? "inline" : "vertical";
      },
    },
    ...mapState({
      sidebarMenu: (state) => state.permissions.sidebarMenu,
      sidebar: (state) => state.app.sidebar,
    }),
  },
  watch: {
    mode(nv, ov) {
      if (nv !== ov) {
        setTimeout(() => {
          this.changePosition();
        }, 0);
      }
    },
  },
  /* updated() {
    this.$nextTick(() => {
      this.changePosition();
    });
  }, */
  methods: {
    changePosition() {
      const dom = document.querySelector(".ant-menu-submenu-popup");
      if (dom) {
        dom.style.left = this.sidebar.opened ? "258px" : "42px";
      }
    },
    //  控制只打开一个
    onOpenChange(openKeys) {
      // 将当前打开的父级菜单存入缓存中
      // window.localStorage.setItem("systemOpenKeys", JSON.stringify(openKeys));
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    // 点击菜单，路由跳转,注意的是当点击MenuItem才会触发此函数
    // eslint-disable-next-line no-unused-vars
    menuClick({ item, key, keyPath }) {
      // 获取到当前的key,并且跳转
      this.$router.push({
        path: key,
      });
    },
    setDefaultMenu() {
      this.rootSubmenuKeys = this.sidebarMenu.map((item) => item.menu_url);
      this.openKeys = [this.rootSubmenuKeys[0]];
    },
  },
  created() {
    // 将从缓存中取出openKeys
    this.setDefaultMenu();
    /*  const openKeys = window.localStorage.getItem("systemOpenKeys");
    if (openKeys) {
      // 存在即赋值
      this.openKeys = JSON.parse(openKeys);
    } */

    // this.getSystemPermission();
  },
};
</script>
<style lang="less" scoped>
</style>
