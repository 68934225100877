import request from "@utils/request";

//用户/列表
export function listUserinfo(query) {
  return request({
    url: "/user/list",
    method: "get",
    params: query,
  });
}

//用户/新增
export function addUserinfo(data) {
  return request({
    url: "/user/add",
    method: "post",
    data: data,
  });
}

//用户/详情
export function detailsUserinfo(query) {
  return request({
    url: "/user/info",
    method: "get",
    params: query,
  });
}

//用户/编辑
export function updataUserinfo(data) {
  console.log("12312",data)
  console.log(typeof(data))
  return request({
    url: "/user/edit",
    method: "put",
    data: data,
  });
}

//用户/删除
export function deleteUserinfo(data) {
  return request({
    url: "/user/del",
    method: "DELETE",
    data: data,
  });
}

//用户/修改密码
export function editPasswordUserinfo(data) {
  return request({
    url: "/user/edit/password",
    method: "put",
    data: data,
  });
}

//用户/确认原始密码

export function originalPasswordUserinfo(query) {
  return request({
    url: "/user/confirm",
    method: "get",
    params: query,
  });
}
