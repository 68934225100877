<template>
  <div class="login-container">
    <div class="login-container-text flex justify-center align-center">
      <div class="flex flex-column justify-center align-center">
        <!-- <img src="@/assets/images/login/login_text.png" class="login-container-text-ch"> -->
        <!-- <div class="login-title-cn">{{ systemNames }}</div> -->
        <!-- <img src="@/assets/images/login/login_text_en.png" class="login-container-text-en"> -->
        <!-- <div class="login-title-en m-t-5">Big Sixiang Sentiment Wisdom Unmanned Farm Platform</div> -->
      </div>
    </div>
    <div class="login-container-form" v-show="loginShowQR">
      <!-- <a-select default-value="cn">
          <a-select-option value="cn"> 中文/简体 </a-select-option>
          <a-select-option value="en"> EngLish </a-select-option>
        </a-select> -->
      <div class="login-container-form-text">{{$t('login.userLogin')}}</div>
      <!-- <img
        @click="cutLoginQR"
        src="@assets/images/loginQR.png"
        alt=""
        class="jiaobiao"
      /> -->

      <a-form-model ref="loginForm" :model="loginForm" :rules="loginRules">
        <!-- 用户名 -->
        <a-form-model-item ref="user_name" prop="user_name" v-if="!flag">
          <span class="svg-container" style="color: #ebebeb">
            <svg-icon icon-class="user" />
          </span>
          <a-input
            v-model="loginForm.user_name"
            :placeholder="$t('login.username')"
            name="username"
            type="text"
            tabindex="1"
            autocomplete="off"
            @blur="
              () => {
                $refs.user_name.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <!-- 密码 -->
        <a-form-model-item ref="password" prop="password" v-if="!flag">
          <span class="svg-container" style="color: #ebebeb">
            <svg-icon icon-class="password" />
          </span>
          <a-input
            :key="passwordType"
            v-model="loginForm.password"
            :type="passwordType"
            :placeholder="$t('login.password')"
            name="password"
            tabindex="2"
            autocomplete="off"
            @blur="
              () => {
                $refs.password.onFieldBlur();
              }
            "
            @keyup.enter.native="handleLogin"
          />
          <span class="show-pwd" @click="showPwd">
            <svg-icon
              :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"
            />
          </span>
        </a-form-model-item>
        <!-- 手机号 -->
        <a-form-model-item v-if="flag" prop="telephone" ref="telephone">
          <span class="svg-container" style="color: #ebebeb">
            <a-icon type="phone" />
          </span>
          <a-input
            :maxLength="11"
            v-model="loginForm.telephone"
            type="text"
            tabindex="3"
            auto-complete="off"
            :placeholder="$t('login.phone')"
            @blur="
              () => {
                $refs.telephone.onFieldBlur();
              }
            "
          >
          </a-input>
        </a-form-model-item>
        <!-- 验证码 -->
        <a-form-model-item
          ref="code"
          prop="code"
          style="width: 70%; margin-right: 8px"
          v-if="flag"
        >
          <span class="svg-container" style="color: #ebebeb">
            <a-icon type="message" />
          </span>
          <a-input
            v-model="loginForm.code"
            type="text"
            :placeholder="$t('login.code')"
            tabindex="4"
            auto-complete="on"
          />
          <a-button
            size="small"
            class="code-img"
            @click="getCode()"
            :disabled="getCodeBtnDisable"
          >
            <span> {{ codeBtnWord}}</span>
          </a-button>
        </a-form-model-item>
        <div class="tips" v-if="flag" @click="nohasCode">{{$t('login.usernameLogin')}}</div>
        <div class="tips" v-if="!flag" @click="hasCode">{{$t('login.codeLogin')}}</div>

        <div class="flex align-center m-b-20">
          <a-checkbox v-model="checked" v-if="!flag">{{$t('login.rememberPassword')}}</a-checkbox>
        </div>
        <a-button
          :loading="loading"
          type="primary"
          style="width: 100%; margin-bottom: 30px"
          @click="handleLogin"
          >{{$t("login.login")}}</a-button
        >
      </a-form-model>
    </div>
    <!-- <div class="login-container-form" v-show="!loginShowQR">
      <div class="login-container-form-text">扫码登录</div>
      <img
        @click="cutLoginPC"
        src="@assets/images/loginPC.png"
        alt=""
        class="jiaobiao"
      />
      <div class="codeQR">
        <img src="@assets/images/QR-code.png" alt="" />
      </div>
      <p style="text-align: center">请使用手机微信扫码登入</p>
    </div> -->
  </div>
</template>

<script>
import { smsCode } from "@/api/login";
// import { clear } from "console";
// import { getToken, setToken } from '@/utils/auth'
// import { mapState } from "vuex";
import storage from "@/utils/storage";
export default {
  name: "Login",
  data() {
    // const _self=this
    const validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("验证码为空"));
        return false;
      } else if (value !== this.identifyCode) {
        callback(new Error("验证码不正确"));
        return false;
      } else {
        callback();
      }
    };
    return {
      flag: false,
      loginShowQR: true,
      time: false,
      systemNames: "",
      codeBtnWord: "获取验证码", // 获取验证码按钮文字
      waitTime: 60, // 获取验证码按钮失效时间
      getCodeBtnDisable: false,
      loginForm: {
        user_name: "",
        password: "",
        telephone: "",
        code: "",
      },
      loginRules: {
        user_name: [
          { required: true, trigger: "blur" ,message: this.$t('login.user_name_rules') },
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" },
        ],
        telephone: [
          { required: true, trigger: "blur", message: "请输入您的手机号码" },
        ],
        code: [
          {
            validator: validateCode,
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
      loading: false,
      passwordType: "password",
      redirect: undefined,
      checked: false,
      imgBase: "",
    };
  },
  computed: {
    // ...mapState({
    //   login_name: (state) => state.user.login_name,
    //   userInfo: (state) => state.userInfo,
    //   systemName: (state) => state.user.systemName,
    // }),
    // getCodeImg() {
    //   return `data:image/jpg;base64,${this.imgBase}`;
    // },
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    const { user_name, password, checked } =
      storage.getItem("rememberUser") || {};
    this.checked = checked;
    this.loginForm.user_name = user_name;
    this.loginForm.password = password;
    // this.user_name = this.login_name;
    // // let user_name = localStorage.getItem("login_name");
    // if (this.user_name) {
    //   this.loginForm.user_name = this.user_name;
    //   this.loginForm.password = Base64.decode(localStorage.getItem("password")); // base64解密
    //   console.log(localStorage.getItem("password"));
    //   this.checked = true;
    // } else {
    //   this.loginForm.user_name = "";
    //   this.loginForm.password = "";
    // }
    // this.systemNames = this.systemName;
    // // this.getSystemInfo();
    // // 获取验证码
    // // this._getVercode();
  },
  methods: {
    // handleChange(value) {
    //   this.$root.Bus.$emit('switchLanguage', value)
    // },
  
    // 切换二维码登录
    cutLoginQR() {
      this.loginShowQR = false;
    },
    // 切换PC登录
    cutLoginPC() {
      this.loginShowQR = true;
    },
    closeTime() {
      this.time = false;
    },
    // 获取验证码
    getCode() {
      this.waitTime--;
      if (this.loginForm.telephone == "") {
        this.$message.error(this.$t('login.phoneNull'));
        return;
      }
      if (
        !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
          this.loginForm.telephone
        )
      ) {
        this.$message.error("请输入正确的手机号");
        return;
      }

      // this.codeBtnWord = `${this.waitTime}s 后获取`;
      smsCode({
        telephone: this.loginForm.telephone,
      })
        .then((res) => {
          this.time = true;
          this.$message.success(res.err_data);
          let that = this;
          that.waitTime--;
          that.getCodeBtnDisable = true;
          this.codeBtnWord = `${this.waitTime}s后重新发送`;
          let timer = setInterval(() => {
            if (that.waitTime > 1) {
              that.waitTime--;
              that.codeBtnWord = `${this.waitTime}s后重新发送`;
            } else {
              clearInterval(timer);
              that.codeBtnWord = "获取验证码";
              that.getCodeBtnDisable = false;
              that.waitTime = 60;
            }
          }, 1000);
        })
        .catch(() => {
          // this.$message.error(res.err_msg);
        });
      // let timer = setInterval(() => {
      //   if (this.waitTime > 1) {
      //     this.waitTime--;
      //     this.codeBtnWord = `${this.waitTime}s 后获取`;
      //   } else {
      //     clearInterval(timer);
      //     this.codeBtnWord = "获取验证码";
      //     this.getCodeBtnDisable = false;
      //     this.waitTime = 61;
      //   }
      // }, 1000);
      //  else {
      //   this.$message.error("请先输入手机号码");
      // }
    },
    // 登录
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.flag === 1) {
            //  如果flag是1就走验证码登录 登录接口只传手机号和验证码
            this.$store
              .dispatch("login_s", {
                telephone: this.loginForm.telephone,
                code: Number(this.loginForm.code),
                login_type: 1,
              })
              .then(() => {
                this.$message.success(this.$t('login.loginSuccess'));
                this.$router.push({ path: this.redirect || "/" });
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            //  如果flag是0就走账号登录 登录接口只账户和密码
            this.$store
              .dispatch("login", {
                user_name: this.loginForm.user_name,
                password: this.loginForm.password,
                login_type: 1,
              })
              .then(() => {
                this.$message.success(this.$t('login.loginSuccess'));
                this.$router.push({ path: this.redirect || "/" });
                this.loading = false;
                this.setRemember();
              })
              .catch(() => {
                this.loading = false;
              });
          }
        }
      });
    },
    setRemember() {
      if (this.checked) {
        storage.setItem("rememberUser", {
          ...this.loginForm,
          checked: this.checked,
        });
      } else {
        storage.setItem("rememberUser", { checked: this.checked });
      }
    },
    nohasCode() {
      this.time = false;
      this.flag = 0;
      this.loginRules = {
        user_name: [
          { required: true, trigger: "blur", message: "请输入您的用户名" },
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" },
        ],
      };
      this.$refs["loginForm"].clearValidate();
    },
    hasCode() {
      console.log("手机动态验证码");
      this.time = false;
      this.flag = 1;
      this.loginRules = {
        telephone: [
          { required: true, trigger: "blur", message: "请输入您的手机号" },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              var passwordreg =
                /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/;
              console.log(passwordreg.test(value));
              if (!passwordreg.test(value)) {
                callback(new Error("请输入正确的手机号码哦!"));
              } else {
                callback();
              }
            },
          },
        ],
        code: [
          { required: true, trigger: "blur", message: "请输入您的动态密码" },
        ],
      };
      this.$refs["loginForm"].clearValidate();
    },
    // _getVercode() {
    //   this.$api.login.getVercode().then((res) => {
    // const { err_code, err_data } = res;
    //     if (err_code === 200) {
    //       const { ver_img, ver_id } = err_data;
    //       this.imgBase = ver_img;
    //       this.loginForm.ver_id = ver_id;
    //     }
    //   });
    // },
    // 获取系统信息
    getSystemInfo() {
      this.$store.dispatch("systemsetting");
    },
    // 明文显示
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      // this.$nextTick(() => {
      //   this.$refs.password.focus();
      // });
    },
  },
};
</script>

<style lang="less" scoped>
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

@bg: #283443;
@light_gray: #fff;
@cursor: #fff;

/* reset element-ui css */
.login-container {
  .el-checkbox__input.is-checked + .el-checkbox__label,
  .ant-checkbox,
  ::v-deep .ant-checkbox-checked .ant-checkbox-inner {
    border-color: rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.2);
  }
  ::v-deep .ant-checkbox-inner {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 0px 1px rgb(0 0 0);
  }
  ::v-deep .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  ::v-deep .ant-checkbox:hover .ant-checkbox-inner,
  ::v-deep .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: rgba(255, 255, 255, 0.3);
  }
  ::v-deep .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: rgba(255, 255, 255, 0.7);
  }
  ::v-deep .ant-checkbox-checked::after {
    border-color: rgba(255, 255, 255, 0.3);
  }
  .ant-btn {
    height: 38px;
    // background: #307AF2;
    border-radius: 4px;
    color: #fff;
  }
  .ant-input {
    display: inline-block;
    height: 30px;
    width: 82%;
  }
  input.ant-input {
    background: rgb(235, 235, 235, 0.3);
    border: 0px;
    -webkit-appearance: none;
    border-radius: 0px;
    // padding: 12px 5px 12px 15px;
    padding: 5px 10px;

    // height: 47px;
    height: 30px;
    caret-color: @cursor;
    outline: none;
    &:-webkit-autofill {
      // box-shadow: 0 0 0px 1000px @bg inset !important;
      // -webkit-text-fill-color: @cursor !important;
    }
    &:focus {
      outline: none;
    }
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-form-item-control {
    position: relative;
    line-height: 0 !important;
    zoom: 1;
  }
  ::v-deep .ant-form-item-children {
    // border: 1px solid rgba(255, 255, 255, 0.1);
    // background: rgba(0, 0, 0, 0.1);
    // border-radius: 5px;
    display: block;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    color: #454545;
    margin-bottom: 5px;
    .code-img {
      position: absolute;
      right: -5.625rem;
      /* height: 2.375rem; */
      background: hsla(0, 0%, 100%, 0.3);
      border-radius: 0.25rem;
      text-align: center;
      color: #000;
      font-size: 0.75rem;
      border-radius: 0.125rem;
      overflow: hidden;
      cursor: pointer;
      padding: 0.3125rem 0.625rem;
      height: 1.875rem;
      /* line-height: 2.5rem; */
      margin-top: 5px;
    }
  }
}

.jiaobiao {
  font-size: 60px;
  color: purple;
  position: absolute;
  right: -1px;
  top: -1px;
  cursor: pointer;
  opacity: 0.7;
}
</style>

<style lang="less" scoped>
@bg: #2d3a4b;
@dark_gray: #889aa4;
@light_gray: #eee;

.login-title-cn {
  font-size: 43px;
  font-family: "YouSheBiaoTiYuan";
  color: #ffffff;
  letter-spacing: 2px;
  background: linear-gradient(180deg, #f0fcff 0%, #72feff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.login-title-en {
  font-size: 15px;
  color: #ffffff;
  letter-spacing: 1.5px;
  line-height: 18px;
  background: linear-gradient(180deg, #cffcff 0%, #a1fdff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: capitalize;
  font-weight: bold;
  font-family: Helvetica;
  opacity: 0.8;
}

.login-container {
  min-height: 100%;
  width: 100%;
  // background-color: $bg;
  background: url("~@/assets/images/login/login_bg.png") no-repeat;
  background-size: cover;
  overflow: hidden;
  &-text {
    background: url("~@/assets/images/login/login_text_en.png") no-repeat;
    height: 164px;
    background-size: 100%;
    > div {
      width: 800px;
      img {
        width: 62%;
      }
    }
  }
  &-form {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background: url("~@/assets/images/login/login_form.png") no-repeat;
    background-size: 100% 100%;
    // width: 25%;
    width: 350px;
    margin: 100px auto;
    position: relative;
    &-text {
      padding: 36px 0 8px;
      font-size: 20px;
      font-weight: 400;
      color: #000;

      text-align: center;
      margin: 0 auto 20px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -4px;
        transform: translateX(-50%);
        width: 80px;
        height: 2px;
        background-color: #307AF2;
      }
    }
  }
  .login-form {
    position: relative;

    max-width: 100%;
    // padding: 160px 35px 0;
    padding: 36px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    cursor: pointer;
    float: right;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    // padding: 6px 5px 6px 15px;
    // color: $dark_gray;
    color: #07c6a1;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
    text-align: center;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: @light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 40px;
    top: 4px;
    font-size: 16px;
    color: #000;
    cursor: pointer;
    user-select: none;
  }
  .icon-zcode {
    background: url("~@/assets/images/login/icon_zcode.png") no-repeat;
    width: 20px;
    height: 20px;
    background-size: 100% 100%;
    display: inline-block;
    position: relative;
    top: 4px;
    // left: -4px;
  }
}
</style>
