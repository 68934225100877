import Cookies from "js-cookie";

const TokenKey = "ACCESS-TOKEN";

const LoginNameKey = "LOGIN_NAME";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function getLogin_name() {
  return Cookies.get(LoginNameKey);
}

export function setLogin_name(login_name) {
  return Cookies.set(LoginNameKey, login_name);
}

export function retLogin_name() {
  return Cookies.remove(LoginNameKey);
}
