<template>
  <div class="top-nav">
    <!-- <img src="@/assets/images/header/back.png" class="head-img" alt="" /> -->
    <!-- <img src="@/assets/images/login/login_text_bg.png" alt="" class="logo" /> -->
    <div class="log flex align-center">
      <img src="@/assets/images/login/login_text_bg.png" alt="" class="logo" />
      <!-- <span class="login-title-cn">{{ systemNames }}</span> -->
    </div>
    <div class="left-menu"></div>
    <div class="right-menu">
      <hamburger
        v-if="!sidebar.hide"
        :is-active="sidebar.opened"
        class="hamburger-container"
        @toggleClick="toggleSideBar"
      />
      <div class="avatar flex align-center">
        <img src="@/assets/images/header/head.png" class="user-avatar" />
        <!-- <span class="m-l-10" style="color: #07c6a1">{{ username }}</span> -->
        <!-- <span style="color: #07c6a1">
          <input
            type="text"
            readonly
            style="
              width: 35%;
              height: 24px;
              font-size: 1px;
              position: absolute;
              bottom: -7%;
              right: 50px;
              text-align: center;
            "
            placeholder="修改密码"
            v-if="show"
            @click="opened" />
          <a-icon type="caret-down" @click="showCont"
        /></span> -->
        <template>
          <a-dropdown>
            <a
              class="ant-dropdown-link m-l-10"
              style="color: #000000"
              @click="(e) => e.preventDefault()"
            >
              {{ username }} <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a
                  href="javascript:;"
                  style="text-align: center"
                  @click="opened"
                  >修改密码</a
                >
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
      </div>
      <div class="exit m-l-25 flex align-center" @click="logout">
        <img
          src="@/assets/images/header/exit.png"
          style="width: 20px; height: 20px; opacity: 1"
        />
      </div>
    </div>
    <password-dialog
      :visible="dialogVisible"
      @close="closeOpened"
      @sure="sureOpened"
    />
  </div>
</template>

<script>
import Hamburger from "@/components/Hamburger";
import { mapState } from "vuex";
import PasswordDialog from "./PasswordDialog.vue";
export default {
  name: "Topbar",
  components: {
    Hamburger,
    PasswordDialog,
  },
  data() {
    return {
      show: false,
      // systemNames: "",
      username: "",
      dialogVisible: false,
      // routes: constantRoutes
      systemNames: "",
    };
  },
  computed: {
    ...mapState({
      login_name: (state) => state.user.login_name,
      systemName: (state) => state.user.systemName,
    }),
    device() {
      return this.$store.state.app.device;
    },

    sidebar() {
      return this.$store.state.app.sidebar;
    },
  },
  created() {
    this.username = this.login_name;
    this.systemNames = this.systemName;

    // this.initCurrentRoutes();
  },
  methods: {
    //点击箭头密码显示和隐藏
    showCont: function () {
      this.show = !this.show;
    },
    //取消
    closeOpened(callback) {
      this.handleDialog(false, () => {
        setTimeout(() => {}, 300);
      });
      callback && callback();
    },
    //确定
    sureOpened(callback) {
      this.handleDialog(false, () => {
        setTimeout(() => {}, 300);
      });

      callback && callback();
    },
    //封装弹框
    handleDialog(isVisible = true, callback = () => {}) {
      this.dialogVisible = isVisible;

      callback && callback();
    },
    //密码弹框
    opened() {
      this.show = !this.show;
      this.handleDialog(true, () => {});
    },

    /* async logout() {
          await this.$store.dispatch('logout')
          this.$router.push(`/login?redirect=${this.$route.fullPath}`)
        }, */
    async logout() {
      const that = this;
      this.$confirm({
        title: "提示",
        content: "确定注销并退出系统吗？",
        onOk() {
          that.$store.dispatch("logout");
        },
        cancelText: "取消",
        onCancel() {},
      });
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
  },
};
</script>

<style>
.hamburger {
  fill: currentColor;
  color: #000;
}
</style>

<style lang="less" scoped>
.hamburger-container {
  line-height: 65px;
  height: 100%;
  float: left;
  cursor: pointer;
  transition: background 0.3s;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background: rgba(0, 0, 0, 0.025);
  }
}

</style>
